import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import QRCode from 'react-qr-code';
import Grid from "@mui/material/Grid";

export default function QrModal(props) {
  const [scroll, setScroll] = React.useState('paper');

  const open = true;
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const { qrOpen, handleClose } = props
  console.log("------- props = ", props)
  return (
    <Dialog
      open={qrOpen}
      onClose={() => handleClose(false)}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">رمز الاستجابة السريعة</DialogTitle>
      <DialogContent dividers={scroll === 'paper'}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >

          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <QRCode id="carQRCode" size={300} value={'https://migdadicars.com'} />
            </Grid>
          </Grid>



        </DialogContentText>
      </DialogContent>
      <DialogActions dir='ltr'>
        <Button onClick={() => handleClose(false)}>أغلق</Button>
        {/* <Button onClick={console.log('handleClose')}>Subscribe</Button> */}
      </DialogActions>
    </Dialog>
  );
}
