import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import QRCode from 'react-qr-code';
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

export default function AlertModal(props) {
  const [scroll, setScroll] = React.useState('paper');

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }, []);

  const { open, handleClose, title, actionTitle, text, callBack } = props
  return (
    <Dialog
      open={open}
      onClose={() => handleClose('')}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <MKTypography verticalAlign='middle' color='text' p={1} fontWeight='bold'>
        {title}
      </MKTypography>
      <DialogContent dividers>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <MKTypography color='dark' p={2} fontWeight='bold'>
            {text}
          </MKTypography>
        </DialogContentText>
      </DialogContent>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <MKButton variant='text' color='text' onClick={() => handleClose('')}>أغلق</MKButton>
        </Grid>
        <Grid item>
          <MKButton variant='text' color='error' onClick={() => callBack()}>{actionTitle}</MKButton>
        </Grid>
      </Grid>
    </Dialog>
  );
}
