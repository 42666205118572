/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const imagesPrefix =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections";


export default [
  {
    title: "المركبات",
    description: "",
    items: [
      {
        id: 1,
        image: 'https://assets9.lottiefiles.com/packages/lf20_DbaHtZ.json',
        name: "مركبات المعرض",
        path: "store",
      },
      {
        id: 2,
        image: 'https://assets9.lottiefiles.com/packages/lf20_N8p92wAFfu.json',
        name: "مركبات المزاد",
        path: "auction"
      },
      {
        id: 3,
        image: 'https://assets7.lottiefiles.com/packages/lf20_EHugAD.json',
        name: "مركبات الصيانة",
        path: "service"
      },
      {
        id: 4,
        image: 'https://assets7.lottiefiles.com/packages/lf20_rg69wuwj.json',
        name: "مركبات مباعه",
        path: "sold-out"
      },
    ],
  },
  // add to show section
];
