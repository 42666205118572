import React, { useState, useEffect } from "react";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Container from "@mui/material/Container";
import IconButton from '@mui/material/IconButton';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ColorLensTwoToneIcon from '@mui/icons-material/ColorLensTwoTone';
import MKButton from "components/MKButton";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import MKBox from "components/MKBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKInput from "components/MKInput";
import Modal from '@mui/material/Modal';
import { YearPicker } from '@mui/x-date-pickers/YearPicker';
import MenuItem from "@mui/material/MenuItem";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import QrCode2Icon from '@mui/icons-material/QrCode2';
import { useParams } from "react-router-dom";
import { useMutation } from '@tanstack/react-query'
import axios from 'axios';
import MKTloading from "components/MKTloading";
import MKAlert from "components/MKAlert";
import MKTypography from "components/MKTypography";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Slide from "@mui/material/Slide";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import QrModal from '../../../components/MKModal/QrModal'
import AlertModal from '../../../components/MKModal/AlertModal'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#ff000000',
};

const brands = [
  'فورد',
  'هونداي',
  'ميتسوبيشي',
  'بي ام دبليو',
  'مرسيدس',
  'هوندا',
  'نيسان',
  'تويوتا',
  'كيا',
  'ليكزاس',
  'شفروليه',
  'اوبل',
  'مازدا',
  'اودي'
]

function CarView(client) {
  const [car, setCar] = useState(null);
  const { id: carId } = useParams();
  const [images, setImages] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [imageSeleced, setImageSeleced] = useState(null);
  const [errors, setErrors] = useState([])
  const minDate = dayjs().subtract(5, 'year');
  const maxDate = dayjs().add(0, 'year');
  const { innerWidth: width, innerHeight: height } = window;
  const [carName, setCarName] = useState('');
  const [carYear, setCarYear] = useState(dayjs());
  const [carPurchasingPrice, setCarPurchasingPrice] = useState('');
  const [carSellingPrice, setCarSellingPrice] = useState('');
  const [carColor, setCarColor] = useState('');
  const [carVinNo, setCarVinNo] = useState('');
  const [carNots, setCarNots] = useState('');
  const [carBrand, setCarBrand] = useState('');
  const [serviceCost, setServiceCost] = useState('');
  const [carStatus, setcarStatus] = useState('');
  const [carCustoms, setCarCustoms] = useState('');
  const [carDistance, setCarDistance] = useState(0);
  const [loading, setLoading] = useState(false)
  const [numPages, setnumPages] = useState(null);
  const [pdfPages, setPdfPages] = useState([]);
  const [qrOpen, setQrOpen] = useState(false);
  const [AlertModalOpen, setAlertModalOpen] = useState('');

  const navigate = useNavigate();

  const setData = async (carData) => {
    await setCarName(carData?.name || '')
    await setCarYear(dayjs(carData?.year || dayjs()))
    await setCarPurchasingPrice(carData?.purchasing_price || '')
    await setCarSellingPrice(carData?.selling_price || '')
    await setCarColor(carData?.color || '#00000')
    await setCarVinNo(carData?.vinNo || '')
    await setCarNots(carData?.notes || '')
    await setCarBrand(carData?.brand || '')
    await setServiceCost(carData.service_cost || 0)
    await setcarStatus(carData?.status || 'auction')
    await setCarCustoms(carData?.customs || 0)
    await setCarDistance(carData?.distance || 0)
    const imgData = carData?.assets?.filter(asset => asset.type.includes('image')) || []
    const pdfData = carData?.assets?.filter(asset => asset.type.includes('pdf')) || []
    await setImages(imgData)
    await setPdfFiles(pdfData)
  }

  const getCar = async () => {
    const token = localStorage.getItem('jwt_access_token')
    await axios.get(`/car/get_car?car_id=${carId}`, {
    }).then(async (response) => {
      setCar(response.data)
      setData(response.data)
    }, (error) => {
    });
  };

  function dataURLtoBlob(dataurl) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
      n -= 1;
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const goToPage = () => {
    navigate(`/car_list/${carStatus}`)
  }

  const mutationCreate = useMutation(async (data) => {
    return await axios.post(
      '/car/create_car',
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  })

  const mutationUpdate = useMutation(async (data) => {
    return await axios.put(
      '/car/update_car',
      data,
      {
        params: {
          car_id: carId
        },
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  })

  const checkValidation = async (igmoreImgs) => {
    const imgsNotSaved = images.filter(item => item.id === null)
    await setErrors([])
    let valuesErrors = []
    if (!igmoreImgs && imgsNotSaved.length > 0) {
      valuesErrors.push('imgsNotSaved')
    }
    if (carName === '') valuesErrors.push('name')
    if (carPurchasingPrice === '') valuesErrors.push('purchasingPrice')
    if (carSellingPrice === '') valuesErrors.push('sellingPrice')
    if (carVinNo === '') valuesErrors.push('vinNo')
    if (carStatus === '') valuesErrors.push('status')
    if (carBrand === '') valuesErrors.push('brand')
    await setErrors(valuesErrors)
    return valuesErrors.length === 0
  }

  const submitForm = async (igmoreImgs = false) => {
    await mutationUpdate.reset()
    await mutationCreate.reset()
    const formIsValid = await checkValidation(igmoreImgs)
    if (formIsValid) {
      const data = {
        name: carName,
        year: carYear.year(),
        color: carColor,
        is_active: true,
        brand: carBrand,
        notes: carNots,
        vinNo: carVinNo,
        purchasing_price: carPurchasingPrice,
        selling_price: carSellingPrice,
        sold_out: false,
        status: carStatus,
        service_cost: serviceCost,
        customs: carCustoms,
        distance: carDistance
      }
      if (carId === 'new') {
        await mutationCreate.mutate(data)
      } else {
        await mutationUpdate.mutate(data)
      }
    }
  }

  if (mutationUpdate.isError || mutationCreate.isError) {
    document.documentElement.scrollTop = 0;
  }

  if (mutationUpdate.isSuccess) {
    mutationUpdate.reset()
    goToPage()
  }

  if (mutationCreate.isSuccess) {
    const dataId = mutationCreate.data.data.id
    mutationCreate.reset()
    navigate(`/view/${dataId}`)
  }

  const handleCapture = (e) => {
    const files = e.target.files
    if (files.length > 10) {
      alert('لا يمكنك ارفاق اكثر من 10 صور')
      return
    }
    Array.from(e.target.files).forEach(file => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imagesData = images
        imagesData.push({ url: e.target.result, type: file.type, id: null, car_id: null })
        setImages([...imagesData])
      };
      reader.readAsDataURL(file);
    })
  }

  const handleSelectPdf = async (e) => {
    const fileReader = new FileReader();
    const files = e.target.files;
    if (files.length > 3) {
      alert('لا يمكنك ارفاق اكثر من 3 ملفات')
      return
    }
    Array.from(e.target.files).forEach(file => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const pdfFilesData = pdfFiles
        pdfFilesData.push({ url: e.target.result, type: file.type, id: null, car_id: null })
        setPdfFiles([...pdfFilesData])
      };
      reader.readAsDataURL(file);
    })
  };

  const removeImage = async (index) => {
    await images.splice(index, 1)
    await setImages([...images])
  }

  const removePdf = async (index) => {
    await pdfFiles.splice(index, 1)
    await setPdfFiles([...pdfFiles])
  }

  const deleteCar = async () => {
    await axios.delete(
      `/car/delete_car`,
      {
        params: {
          'car_id': carId,
        },
      }
    ).then(async ({ data }) => {
      if (data.deleted) {
        const carDeleted = data.car
        navigate(`/car_list/${carDeleted.status}`)
      }
    }, (error) => {
      alert("error to delete")
    });
  }


  const uploadImage = async (file, index, type = 'img') => {
    setLoading(true)
    const form = new FormData();
    const dataToPush = dataURLtoBlob(file.url)
    form.append('assets', dataToPush);
    await axios.post(
      '/car/car_assets?type=pdf',
      form,
      {
        params: {
          'car_id': carId,
          'primary_index': '0'
        },
        headers: {
          ...form.headers,
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then(async (response) => {
      let currentIds = []
      if (type === 'pdf') {
        currentIds = pdfFiles.map(item => item.id)
        const newPdf = response.data.assets.filter(item => item.id && !currentIds.includes(item.id) && !item.type.includes("image"))
        pdfFiles.splice(index, 1)
        pdfFiles.splice(index, 0, newPdf[0])
        await setPdfFiles([...pdfFiles])
      } else {
        currentIds = images.map(item => item.id)
        const newImg = response.data.assets.filter(item => item.id && !currentIds.includes(item.id) && item.type.includes("image"))
        images.splice(index, 1)
        images.splice(index, 0, newImg[0])
        await setImages([...images])
      }
      setLoading(false)
    }, (error) => {
      setLoading(false)
      alert("error to save")
    });
  }

  const renderImgs = () => {
    return (<>
      <Grid item xs={12} md={12} />
      <ImageList cols={5} sx={{ width: 140 * 5, height: 140 }}>
        {images.map((image, index) => (
          <ImageListItem key={index}>
            <img
              onClick={() => setImageSeleced(image)}
              src={image.url}
              alt='test'
              loading="lazy"
              style={{
                width: '135px',
                height: '135px',
                objectFit: 'cover',
                border: '0.5px solid lightgray',
                borderRadius: '5px',
                overflow: 'hidden',
                cursor: '-webkit-zoom-in',
              }}
            />
            {
              image.id === null && <ImageListItemBar
                style={{ background: 'rgb(0 0 0 / 50%)', paddingRight: '8px', zIndex: 2 }}
                actionIcon={
                  <Grid container item md={12}>
                    <Grid container item md={6} py={1}>

                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${'item.title'}`}
                      >
                        <DeleteIcon onClick={() => removeImage(index)} />
                      </IconButton>
                    </Grid>
                    <Grid container item md={6} py={1}>
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${'item.title'}`}
                      >
                        <FileUploadIcon onClick={() => uploadImage(image, index)} />
                      </IconButton>
                    </Grid>
                  </Grid>
                }
              />
            }
          </ImageListItem>
        ))}
      </ImageList>
    </>)
  }

  const onDocumentLoadSuccess = (e, id) => {
    setPdfPages([...pdfPages, { id: id, pages: e.numPages, page: 1 }])
    setnumPages(numPages)
  }

  const updatePdfPage = (id, num) => {
    const pdfPagesSelected = pdfPages.find(item => item.id === id)
    pdfPagesSelected.page = pdfPagesSelected.page + num
    setPdfPages([...pdfPages])
  }

  const pdfStyle = () => {
    return {
      border: '1px solid lightgray',
      height: '500px',
      overflow: 'scroll',
      borderRadius: '5px',
      padding: '20px 50px',
      backgroundColor: '#8080801f',
      marginTop: '30px',
      textAlign: 'center',
    }
  }

  const renderPdfButtons = (id) => {

    const selected = pdfPages.find(item => item.id === id)

    if (!selected) return (<></>)
    const nextActive = selected.page < selected.pages
    const backActive = selected.page > 1
    if (selected.pages > 1) {
      return (
        <div>
          <IconButton
            sx={{ color: 'black' }}
            aria-label={`info about ${'item.title'}`}
            disabled={!nextActive}
          >
            <ArrowForwardIosIcon onClick={() => updatePdfPage(id, 1)} />
          </IconButton>
          {selected.pages} / {selected.page}
          <IconButton
            sx={{ color: 'black' }}
            aria-label={`info about ${'item.title'}`}
            disabled={!backActive}
          >
            <ArrowBackIosIcon onClick={() => updatePdfPage(id, -1)} />
          </IconButton>
        </div>
      )
    } else {
      return (<>1 / 1</>)
    }
  }

  const renderPdfFiles = () => {
    return (
      <Grid item xs={12} md={12} >
        {
          pdfFiles.map((file, index) => (
            <Grid>
              {file.id === null &&
                <MKBox>
                  <Grid container item md={6} py={1}>
                    <IconButton aria-label="delete">
                      <FileUploadIcon onClick={() => uploadImage(file, index, 'pdf')} />
                    </IconButton>
                    <IconButton aria-label="delete">
                      <DeleteIcon onClick={() => removePdf(index)} />
                    </IconButton>
                    {/* </Grid>
                  <Grid container item md={6} py={1}> */}
                  </Grid>
                </MKBox>
              }
              {
                file.id !== null && <div style={pdfStyle()}>
                  <Document file={`${file.url}`} onLoadSuccess={(event) => onDocumentLoadSuccess(event, file.id)}>
                    <Page pageNumber={pdfPages.find(item => item.id === file.id)?.page || 0} />
                  </Document>
                  {renderPdfButtons(file.id)}
                </div>
              }
              {
                file.id === null && <embed style={{ width: '100%', height: 480, margin: 10 }} key={index} src={file.url} />
              }
            </Grid>
          ))
        }
      </Grid>
    )
  }

  const renderImgView = () => {
    return (
      <Modal
        open={imageSeleced != null}
        onClose={() => setImageSeleced(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={[style, { width: width * 6 / 10, height: width * 5 / 10 }]}>
          <img
            src={imageSeleced?.url}
            alt='test'
            loading="lazy"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '30px',
              overflow: 'hidden'
            }}
          />
        </MKBox>
      </Modal>
    )
  }

  const { mutate } = useMutation(getCar);
  useEffect(() => {
    mutationCreate.reset()
    mutationUpdate.reset()
    if (carId === "new") {
      setData({})
      return
    }
    mutate()

  }, []);

  if (loading || mutationCreate.isLoading || mutationUpdate.isLoading) {
    return (<MKTloading />)
  } else {
    return (
      <>
        <QrModal qrOpen={qrOpen} handleClose={setQrOpen} />
        <AlertModal open={AlertModalOpen !== ''} text={AlertModalOpen} title='تاكيد الحذف' handleClose={setAlertModalOpen} actionTitle='احذف' callBack={deleteCar} />
        <DefaultNavbar
          routes={[]}
          sticky
        />
        {renderImgView()}
        <MKBox
          minHeight="15vh"
          width="100%"
          sx={{
            backgroundColor: carColor,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
          }}
        >
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -4,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Slide direction="down" in={mutationUpdate.isError || mutationCreate.isError} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="40%"
                mx="auto"
                color="error"
                setloading
                sx={{ minHeight: "2.5rem !important", py: 1, justifyContent: "center" }}
              >
                <MKTypography variant="body2" color="white" fontWeight="regular">
                  لا يمكن حفظ معلومات المركبه في الوقت الحالي يرجى المحاوله لاحقا
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <MKButton
                component={Link}
                to={carId === 'new' ? '/cars' : `/car_list/${car?.status}`}
                variant="gradient"
                size="small"
                color="info"
              >
                العوده الى القائمه
              </MKButton>
              {carId !== 'new' &&
                <MKButton
                  variant="gradient"
                  size="small"
                  color="error"
                  onClick={() => setAlertModalOpen("هل انت متاكد من حذف المركبة؟")}
                  // onClick={() => deleteCar()}
                  style={{ margin: 3 }}
                >
                  حذف المركبة
                </MKButton>
              }
            </Grid>
            {/* <Grid item>
              <IconButton
                sx={{ color: 'black' }}
              >
                <QrCode2Icon onClick={() => setQrOpen(true)} />
              </IconButton>
            </Grid> */}
          </Grid>
          <MKBox component="section" py={10}>
            <Container>
              <Grid
                container
                item
                justifyContent="center"
                xs={10}
                lg={7}
                mx="auto"
                textAlign="center">
                <MKTypography variant="h3" mb={1}>
                  معلومات السيارة
                </MKTypography>
              </Grid>
              <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
                <MKBox width="100%" component="form" method="post" autoComplete="off" onSubmit={submitForm}>
                  <MKBox p={3}>
                    <Grid item container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <YearPicker
                            date={carYear}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(newDate) => setCarYear(newDate)}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <MKInput
                          label="الماركة"
                          select
                          type='select'
                          error={errors.includes('brand')}
                          onChange={(event) => setCarBrand(event.target.value)}
                          value={carBrand}
                          variant="standard"
                          height={100}
                          fullWidth >
                          {
                            brands.map(brandItem => {
                              return (
                                <MenuItem key={brandItem} value={brandItem}>
                                  {brandItem}
                                </MenuItem>
                              )
                            })
                          }
                        </MKInput>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          label="الاسم"
                          error={errors.includes('name')}
                          onChange={(event) => setCarName(event.target.value)}
                          value={carName}
                          variant="standard"
                          fullWidth />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <MKInput
                          label="الحالة"
                          select
                          type='select'
                          error={errors.includes('status')}
                          onChange={(event) => setcarStatus(event.target.value)}
                          value={carStatus}
                          variant="standard"
                          fullWidth >
                          <MenuItem key="auction" value='auction'>
                            في المزاد
                          </MenuItem>
                          <MenuItem key="store" value='store'>
                            في المعرض
                          </MenuItem>
                          <MenuItem key="service" value='service'>
                            في الصيانة
                          </MenuItem>
                          <MenuItem key="sold-out" value='sold-out'>
                            مباعه
                          </MenuItem>
                        </MKInput>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <MKInput
                          label="سعر الشراء"
                          type='number'
                          value={carPurchasingPrice}
                          inputProps={{ step: "500" }}
                          error={errors.includes('purchasingPrice')}
                          onChange={(event) => setCarPurchasingPrice(event.target.value)}
                          variant="standard"
                          fullWidth />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <MKInput
                          label="تكلفة الجمرك"
                          type='number'
                          value={carCustoms}
                          inputProps={{ step: "500" }}
                          onChange={(event) => setCarCustoms(event.target.value)}
                          variant="standard"
                          fullWidth />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <MKInput
                          label="تكلفة الصيانه"
                          type='number'
                          value={serviceCost}
                          inputProps={{ step: "500" }}
                          error={errors.includes('purchasingPrice')}
                          onChange={(event) => setServiceCost(event.target.value)}
                          variant="standard"
                          fullWidth />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <MKInput
                          label="سعر البيع"
                          value={carSellingPrice}
                          inputProps={{ step: "500" }}
                          type='number'
                          error={errors.includes('sellingPrice')}
                          onChange={(event) => setCarSellingPrice(event.target.value)}
                          variant="standard"
                          fullWidth />
                      </Grid>
                      <Grid item xs={3}>
                        <MKInput
                          label="المسافة المقطوعة"
                          inputProps={{ step: "1000" }}
                          type='number'
                          onChange={(event) => setCarDistance(event.target.value)}
                          variant="standard"
                          value={carDistance}
                          fullWidth />
                      </Grid>
                      <Grid item xs={9}>
                        <MKInput
                          label="رقم الشصي"
                          error={errors.includes('vinNo')}
                          onChange={(event) => setCarVinNo(event.target.value)}
                          variant="standard"
                          value={carVinNo}
                          fullWidth />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          label="ملاحظات"
                          onChange={((event) => setCarNots(event.target.value))}
                          variant="standard"
                          fullWidth
                          value={carNots}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ display: { xs: "block", md: "none" } }}>
                        <MKInput
                          label="لون المركبة"
                          onChange={(event) => setCarColor(event.target.value)}
                          variant="standard"
                          type="color"
                          fullWidth
                          value={carColor}
                        />
                      </Grid>
                      {carId !== 'new' && <>
                        <Grid item xs={6} md={3}>
                          <IconButton style={{ backgroundColor: '#01010108' }} color="secondary" aria-label="upload picture" component="label" size="large">
                            <input disabled={images.length >= 10} onChange={handleCapture} hidden accept="image/*" type="file" multiple />
                            <PhotoCamera fontSize="inherit" />
                          </IconButton>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <IconButton style={{ backgroundColor: '#01010108' }} color="secondary" aria-label="upload picture" component="label" size="large">
                            <input disabled={pdfFiles.length >= 3} onChange={handleSelectPdf} hidden accept="application/pdf" type="file" multiple />
                            <PictureAsPdfIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                      </>
                      }
                      <Grid item xs={3} md={3} sx={{ display: { xs: "none", md: "block" } }}>
                        <IconButton style={{ backgroundColor: '#01010108' }} color="secondary" aria-label="upload picture" component="label" size="large">
                          <input onChange={(event) => setCarColor(event.target.value)} hidden type="color" />
                          <ColorLensTwoToneIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={3} md={3} sx={{ display: { xs: "none", md: "block" } }}>
                        <IconButton style={{ color: carColor, backgroundColor: '#01010108' }} aria-label="upload picture" component="label" size="large">
                          <DriveEtaIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                      {images.length > 0 && renderImgs()}
                      {pdfFiles.length > 0 && renderPdfFiles()}
                    </Grid>
                    {
                      errors.includes('imgsNotSaved') && <Grid item justifyContent="center" xs={12} my={2}>
                        <MKButton onClick={() => submitForm(true)} variant="outlined" color="error" fullWidth>
                          هناك صور لم تقم بحفظها, ان كنت لا ترغب بحفظها انقر هنا او احذفها وحاول مجددا
                        </MKButton>
                      </Grid>
                    }
                    <Grid item justifyContent="center" xs={12} my={2}>
                      <MKButton onClick={() => submitForm()} variant="outlined" color="secondary" fullWidth>
                        حفظ
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Container>
          </MKBox>

        </Card>
      </>);
  }
}
export default CarView;
