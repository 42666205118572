import React, { useState, useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKTloading from "components/MKTloading";
import Divider from "@mui/material/Divider";

// import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Cars/sections/Counters";
import DesignBlocks from "pages/Cars/sections/DesignBlocks";
import CarFormDialog from "./components/CarForm";
// Presentation page components
// import BuiltByDevelopers from "pages/Cars/components/BuiltByDevelopers";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import { useMutation } from '@tanstack/react-query'
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function Cars() {
  const [open, setOpen] = useState(false);
  const [carData, setCarData] = useState(false);
  const [cars, setCars] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getCars = async (page = 0, lim = 10) => {
    const token = localStorage.getItem('jwt_access_token')
    setIsLoading(true)
    await axios.get('/car/list_cars', {
    }).then(async (response) => {
      setIsLoading(false)
      await setCars(response.data)
    }, (error) => {
      setIsLoading(false)
    });
  };

  const { mutate } = useMutation(getCars);

  useEffect(() => {
    mutate()
  }, []);

  if (isLoading || !cars) {
    return (<MKTloading />)
  }

  const handleSelect = async (item) => {
    await setCarData(item)
  }

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/view/new",
          label: "إضافة سيارة جديدة",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              المقدادي للسيارات
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              تخصص بيع سيارات الهايبرد والكهرباء
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid item xs={12} md={12} sx={{ display: { xs: "none", md: "block" } }}>
          <Counters cars={cars} />
        </Grid>
        <DesignBlocks cars={cars} />
      </Card>
    </>
  );
}

export default Cars;
