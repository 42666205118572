/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Cars from "layouts/pages/cars";
import CarList from "layouts/pages/cars/carList";
import CarView from "layouts/pages/cars/carView";
import { QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query'
import MKTloading from "components/MKTloading";
import { useNavigate } from "react-router-dom";

// Material Kit 2 React routes
import routes from "routes";

import SignIn from "layouts/pages/authentication/sign-in";


import axios from 'axios';

const queryClient = new QueryClient()
export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const token = localStorage.getItem('jwt_access_token')
  // axios.defaults.baseURL = 'http://localhost:8000/api';
  axios.defaults.baseURL = 'https://api.migdadicars.com/api';
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axios.defaults.headers.accept = 'application/json';


  const checkUser = async () => {

    await axios.get('/users/me')
      .then(data => {
        setIsLoading(false)
      })
      .catch(async (error) => {
        setIsLoading(false)
        const statusCode = error.response.status
        if (statusCode === 401) {
          await localStorage.removeItem('jwt_access_token')
          navigate('/')
        }
      })
  }

  useEffect(() => {
    checkUser()
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const getRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
  //     if (route.collapse) {
  //       return getRoutes(route.collapse);
  //     }

  //     if (route.route) {
  //       return <Route exact path={route.route} element={route.component} key={route.key} />;
  //     }

  //     return null;
  //   });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <Routes>
          {/* {getRoutes(routes)} */}
          <Route path="/sign-in" element={<SignIn />} />
          {!!localStorage.getItem('jwt_access_token') && <>
            <Route path="/cars" element={<Cars />} />
            <Route path="/car_list/:status" element={<CarList />} />
            <Route path="/view/:id" element={<CarView client={queryClient} />} />
            <Route path="*" element={<Navigate to="/cars" />} />
          </>}
          {!!!localStorage.getItem('jwt_access_token') && <>
            <Route path="*" element={<Navigate to="/sign-in" />} />
          </>}
        </Routes>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
