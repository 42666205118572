import React, { useState, useEffect } from "react";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Container from "@mui/material/Container";
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MKBox from "components/MKBox";
import bgImage from "assets/images/bg-presentation.jpg";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKAvatar from "components/MKAvatar";
import MKInput from "components/MKInput";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import { useMutation } from '@tanstack/react-query'
import axios from 'axios';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function CarList() {
  const [requests, setRequests] = useState([]);
  const { status: carStatus } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [cars, setCars] = useState(false);
  const [carsFiltered, setCarsFiltered] = useState(false);
  const navigate = useNavigate();

  const sort = async (data) => {
    data.sort(function (a, b) {
      return b.id - a.id;
    });
    await setCars(data)
    await setCarsFiltered(data)
  }

  const filterCars = (event) => {
    const key = event.target.value
    const dataFiltered = cars.filter(car => car.name.includes(key) || car.brand.includes(key))
    setCarsFiltered(dataFiltered)
  }

  const t = (str) => {
    if (str === 'store') return 'المعرض'
    if (str === 'auction') return 'المزاد'
    if (str === 'service') return 'الصيانة'
    if (str === 'sold-out') return 'مباعه'
  }
  const getCars = async () => {
    const token = localStorage.getItem('jwt_access_token')
    setIsLoading(true)
    const response = await axios.get(`/car/cars_status?status=${carStatus}`, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      setIsLoading(false)
      sort(response.data)
    }, (error) => {
      setIsLoading(false)
    });
  };

  const { mutate } = useMutation(getCars);
  useEffect(() => {
    mutate()
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - carsFiltered.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!carsFiltered) {
    return (<></>)
  }

  const renderTable = () => {
    return (
      <TableContainer component={Paper} dir="ltr">
        <Table sx={{ minWidth: 200 }} aria-label="custom pagination table">
          <TableHead style={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell align="right"></TableCell>
              <TableCell align="right">رقم الشصي</TableCell>
              <TableCell align="right">المسافة المقطوعة</TableCell>
              <TableCell align="right">سعرمجمرك</TableCell>
              <TableCell align="right">سعر حره</TableCell>
              <TableCell align="right">الموديل</TableCell>
              <TableCell align="right">الاسم</TableCell>
              <TableCell align="right">الماركه</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? carsFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : carsFiltered
            ).map((row) => (
              <TableRow key={row.id} >
                <TableCell align="center">
                  <IconButton onClick={() => navigate(`/view/${row.id}`)} style={{ color: 'gray', backgroundColor: '#01010108', marginRight: 10 }} aria-label="upload picture" component="label" size="large">
                    <VisibilityIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton style={{ color: row.color, backgroundColor: '#01010108' }} aria-label="upload picture" component="label" size="large">
                    <DriveEtaIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
                <TableCell align="right">
                  {row.vinNo}
                </TableCell>
                <TableCell align="right">
                  {row.distance}
                </TableCell>
                <TableCell align="right">
                  {(row?.selling_price || 0) +  (row?.customs || 0)}
                </TableCell>
                <TableCell align="right">
                  {row.selling_price}
                </TableCell>
                <TableCell align="right">
                  {row.year}
                </TableCell>
                <TableCell align="right">
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  {row.brand}
                </TableCell>
                <TableCell align="right">
                  {
                    row.assets.filter(asset => asset.type.includes('image')).length > 0 && <MKAvatar
                      src={row.assets.filter(asset => asset.type.includes('image'))[0].url}
                      alt={row.name}
                      variant="rounded"
                      size="lg"
                      shadow="md"
                    // sx={{ mt: -5, mb: 1 }}
                    />
                  }
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25]}
                colSpan={3}
                count={carsFiltered?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={"عدد المركبات في كل صفحة"}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }

  return (
    <>
      <DefaultNavbar
        routes={[]}
        sticky
        title={t(carStatus)}
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container item xs={12} lg={12} py={1} mx="auto">
          <MKButton
            component={Link}
            to={'/cars'}
            variant="gradient"
            size="small"
            color="info"
          >
            الصفحة الرئيسيه
          </MKButton>
          <Grid container item xs={8} lg={8} mx="auto">
            <MKInput
              variant="standard"
              placeholder="البحث حسب الاسم او الماركه"
              fullWidth
              onChange={filterCars}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {renderTable()}
      </Card>
    </>);
}
export default CarList;
