import Lottie from 'react-lottie';
import MKBox from "components/MKBox";
import Container from "@mui/material/Container";

const Loading = () => {
  return (<>
    <MKBox
      minHeight="100vh"
      width="100%"
      sx={{
        // backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "grid",
        placeItems: "center",
      }}
    >
      <Container>
        <Lottie
          options={{
            path: 'https://assets6.lottiefiles.com/packages/lf20_h5nbwk3t.json',
          }}
          width={100}
          height={100}
        />
      </Container>
    </MKBox>
  </>)
}

export default Loading
