/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Lottie from 'react-lottie';
import MKBadge from "components/MKBadge";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import defaultCarImage from "assets/images/default-sedan.jpeg";
import Divider from '@mui/material/Divider';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
function CarCard({ item, count, ...rest }) {

  const { name, image, path } = item
  const defaultOptions = {
    loop: false,
    autoplay: false,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const imageTemplate = (
    <MKBox
      bgColor="white"
      borderRadius="xl"
      shadow="lg"
      height="10rem"
      sx={{
        overflow: "hidden",
        transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
        transformOrigin: "50% 0",
        backfaceVisibility: "hidden",
        willChange: "transform, box-shadow",
        transition: "transform 200ms ease-out",

        "&:hover": {
          transform: "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)",
        },
      }}
      {...rest}
    >
      <MKBox position="absolute" top={0} right={0} zIndex={2} p={1}>
        <MKBadge badgeContent={count} color="light" container />
      </MKBox>
      <MKBox
        component="contaner"
        width="100%"
        my="auto"
      >
        <Lottie
          options={{
            path: image,
            loop: false,
          }}
        />
      </MKBox>
    </MKBox>
  );

  const toLink = `/car_list/${path}`
  return (
    <MKBox
      component={Link}
      to={toLink}
    >
      {imageTemplate}
      <MKBox borderRadius="xl" mt={1} p={1} lineHeight={1}>
        <MKTypography variant="h6" fontWeight="bold">
          {name}
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

// Setting default props for the CarCard
CarCard.defaultProps = {
  name: "",
  count: 0,
  soldOut: false,
};

// Typechecking props for the CarCard
CarCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string,
  count: PropTypes.number,
  soldOut: PropTypes.bool,
};

export default CarCard;
