/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function Counters({ cars }) {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={2}>
            <DefaultCounterCard
              count={cars?.filter(car => car.status !== 'sold-out')?.length || 0}
              title="المجموع الكلي"
              description="مجموع المركبات الكلي"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <DefaultCounterCard
              count={cars?.filter(car => car.status === 'store')?.length || 0}
              title="المعرض"
              description="مجموع المركبات الموجوده داخل المعرض"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <DefaultCounterCard
              count={cars?.filter(car => car.status === 'auction')?.length || 0}
              title="المزاد"
              description="مجموع المركبات الموجوده في المزاد"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <DefaultCounterCard
              count={cars?.filter(car => car.status === 'sold-out')?.length || 0}
              title="المباعة"
              description="مجموع المركبات المباعة من المعرض خلال اخر سنه "
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <DefaultCounterCard
              count={cars?.filter(car => car.status === 'service')?.length || 0}
              title="الصيانه"
              description="مجموع المركبات الموجوده في مركز الصيانه"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
