/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
// import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import SimpleFooter from "examples/Footers/SimpleFooter";

// Material Kit 2 React page layout routes
// import routes from "routes";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpg";

//react-query
import { useMutation } from '@tanstack/react-query'

// axios
import axios from 'axios';

function SignInBasic() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, serError] = useState('');

  const addSession = async (access_token) => {
    localStorage.setItem('jwt_access_token', access_token)
  }

  const login = async () => {
    if (email === '' || password === '') {
      serError('ادخل اسم المستخدم وكلمة المرور')
      return
    }
    await axios.post(
      '/auth/jwt/login',
      new URLSearchParams({
        'username': email,
        'password': password
      }),
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        }
      }).then((response) => {
        addSession(response.data.access_token)
        navigate("/cars")
      }, (error) => {
        serError("اسم المستخدم أو كلمة المرور غير صحيحه")
      });
  };

  const { mutate } = useMutation(login);

  const loginUser = async () => {
    mutate()
  }
  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  المقدادي لتجارة السيارات
                </MKTypography>

              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput onChange={(event) => setEmail(event.target.value)} variant="standard" type="email" label="اسم المستخدم" fullWidth />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput onChange={(event) => setPassword(event.target.value)} variant="standard" type="password" label="كلمة السر" fullWidth />
                  </MKBox>
                  {
                    error && <MKBox mb={2}>
                      <MKTypography variant="h6" color='error'>
                        {error}
                      </MKTypography>
                    </MKBox>
                  }
                  <MKBox mt={4} mb={1}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={() => loginUser()}
                    >
                      تسجيل دخول
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default SignInBasic;
